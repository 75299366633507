import {Box, FormField, Icon, Input, Link, LoaderButton} from '@sproutsocial/racine';
import Layout from '../Layout';
import MobileFriendlyItemDisplay from '../MobileFriendlyItemDisplay';
import Table from '../Table';
import {gql, type Trip} from '../../graphql';
import {useMutation, useQuery} from '@apollo/client';
import {SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {DateTime} from 'luxon';
import DatePicker from '../DatePicker';
import {useTextInputState} from '../../hooks';
import {formatFullDate, formatIsoDate, parseIsoDate} from 'shared/src/datetime';
import {List} from '../List';
import {useIsMobile} from '../../util/mobile';

const GET_TRIPS = gql(/* GraphQL */ `
	query GetTrips($year: Int!) {
		trips(year: $year) {
			id
			tripDate
			destination
			mileage
			memo
		}
	}
`);

const CREATE_TRIP = gql(/* GraphQL */ `
	mutation CreateTrip($newTrip: NewTripInput) {
		createTrip(newTrip: $newTrip) {
			id
		}
	}
`);

const DELETE_TRIP = gql(/* GraphQL */ `
	mutation DeleteTrip($tripId: Int!) {
		deleteTrip(id: $tripId)
	}
`);

const Trips = () => {
	const isMobile = useIsMobile();

	const {data: {trips} = {}} = useQuery(GET_TRIPS, {
		variables: {
			year: 2024,
		},
	});

	const [tripDate, setTripDate] = useState<DateTime>(DateTime.now());
	const [destination, onDestinationChange] = useTextInputState('');
	const [mileage, onMileageChange] = useTextInputState('');
	const [memo, onMemoChange] = useTextInputState('');

	const [createTrip, {loading: isCreatingTrip}] = useMutation(CREATE_TRIP);
	const canCreateTrip = useMemo(() => {
		if (!destination) {
			return false;
		}

		const potentialMileage = parseInt(mileage, 10);
		if (!potentialMileage || potentialMileage <= 0 || isNaN(potentialMileage)) {
			return false;
		}

		return true;
	}, [destination, mileage]);
	const onCreateTrip = useCallback(async () => {
		await createTrip({
			variables: {
				newTrip: {
					tripDate: formatIsoDate(tripDate),
					mileage: parseInt(mileage, 10),
					destination,
					memo,
				},
			},
			refetchQueries: [GET_TRIPS],
		});

		onDestinationChange(null, '');
		onMileageChange(null, '');
		onMemoChange(null, '');
	}, [createTrip, tripDate, destination, mileage, memo, onDestinationChange, onMileageChange, onMemoChange]);

	const [deleteTrip] = useMutation(DELETE_TRIP);
	const onDeleteTrip = useCallback(async (event: SyntheticEvent<HTMLButtonElement>) => {
		await deleteTrip({
			variables: {
				tripId: parseInt(event.currentTarget.dataset['tripId']!, 10),
			},
			refetchQueries: [GET_TRIPS],
		})
	}, [deleteTrip]);

	return (
		<Layout>
			<Layout.Header title='Trips' />
			<Layout.Body>
				<MobileFriendlyItemDisplay
					list={
						<List id='trips'>
							{(trips ?? []).map((trip) => (
								<List.Item
									key={trip.id}
									title={trip.destination}
									description={formatFullDate(parseIsoDate(trip.tripDate))}
									data={`${trip.mileage} mi`}
									subdata={trip.memo}
								/>
							))}
						</List>
					}
					table={
						<Table
							id='trips'
							head={[
								{
									id: 'date',
									content: 'Date',
								},
								{
									id: 'destination',
									content: 'Destination',
								},
								{
									id: 'mileage',
									content: 'Mileage',
								},
								{
									id: 'memo',
									content: 'Memo',
								},
								{
									id: 'delete',
									content: '',
								},
							]}
							items={trips ?? []}
							generateRow={(trip: Trip) => ({
								id: trip.id,
								cells: [
									trip.tripDate,
									trip.destination,
									trip.mileage,
									trip.memo,
									<Link onClick={onDeleteTrip} data-trip-id={trip.id}>
										<Icon name='trash-outline' />
									</Link>
								],
							})}
						/>
					}
					mb='space.500'
				/>

				<Box
					display='flex'
					flexDirection='column'
					px={isMobile ? 'space.400' : 0}
				>
					<Box
						display='flex'
						flexDirection={isMobile ? 'column' : 'row'}
					>
						<FormField label='Date' mr='space.400'>
							{(props) => (
								<DatePicker
									{...props}
									value={tripDate}
									onChange={setTripDate}
								/>
							)}
						</FormField>
						<FormField
							label='Destination'
							width={isMobile ? '100%' : '20%'}
							mr='space.400'
						>
							{(props) => (
								<Input
									{...props}
									name='destination'
									type='text'
									value={destination}
									onChange={onDestinationChange}
								/>
							)}
						</FormField>
						<FormField
							label='Mileage'
							width={isMobile ? '100%' : 100}
							mr='space.400'
						>
							{(props) => (
								<Input
									{...props}
									name='mileage'
									type='number'
									value={mileage}
									onChange={onMileageChange}
								/>
							)}
						</FormField>
						<FormField
							label='Memo'
							width={isMobile ? '100%' : '25%'}
							mr='space.400'
						>
							{(props) => (
								<Input
									{...props}
									name='memo'
									type='text'
									value={memo}
									onChange={onMemoChange}
								/>
							)}
						</FormField>
					</Box>
					<Box>
						<LoaderButton
							appearance='primary'
							disabled={!canCreateTrip}
							isLoading={isCreatingTrip}
							onClick={onCreateTrip}
						>
							Create Trip
						</LoaderButton>
					</Box>
				</Box>
			</Layout.Body>
		</Layout>
	);
};

export default Trips;