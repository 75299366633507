import {DateTime} from 'luxon';
import {Box, Image, Text} from '@sproutsocial/racine';
import {useQuery} from '@apollo/client';
import {gql} from '../../graphql';

const ACCENT_COLOR = 'blue.700';

interface InvoiceHeaderPreviewProps {
	invoiceId: number;
}

const GET_INVOICE_HEADER = gql(/* GraphQL */ `
	query GetInvoiceHeader($id: Int!) {
		invoice(id: $id) {
			id
			date
			description
			customer {
				name
				email
				phoneNumber
			}
		}
	}
`);

const InvoiceHeaderPreview = ({invoiceId}: InvoiceHeaderPreviewProps) => {
	const {data: {invoice} = {}} = useQuery(GET_INVOICE_HEADER, {
		variables: {
			id: invoiceId,
		},
	});
	if (!invoice) {
		return null;
	}

	return (
		<>
			<Box width='100%'>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Box display='flex'>
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							width={60}
							height={60}
							bg={ACCENT_COLOR}
							overflow='hidden'
							borderRadius='outer'
							mr='space.500'
						>
							<Image src={`${process.env.PUBLIC_URL}/gk-logo.png`} height='75%' />
						</Box>
						<Box>
							<Text fontSize='200' fontWeight='semibold' lineHeight={1}>
								Handcrafted by GK
							</Text>
							<br />
							<Text fontSize='200' lineHeight={1}>
								1414 W Barry Ave <br />
								Chicago, IL 60657
							</Text>
						</Box>
					</Box>
					<Box display='flex' flexDirection='column' height='100%'>
						<Text fontSize='200' fontWeight='semibold'>
							Invoice # {invoice.id.toString().padStart(4, '0')} <br />
							Issue Date
						</Text>
						<Text fontSize='200' ml='space.400'>
							{DateTime.fromISO(invoice.date).toLocaleString(DateTime.DATE_MED)}
						</Text>
					</Box>
				</Box>

				<Box width='100%' height={6} bg={ACCENT_COLOR} mt='space.500' />
			</Box>

			<Box display='flex' mt='space.400'>
				<Box
					flex={1}
					display='flex'
					flexDirection='column'
					borderTop='2px solid black'
					mr='space.400'
				>
					<Text fontSize='200' fontWeight='semibold'>
						CUSTOMER
					</Text>
					<Text fontSize='200'>{invoice.customer.name}</Text>
					{invoice.customer.email && <Text fontSize='200'>{invoice.customer.email}</Text>}
					{invoice.customer.phoneNumber && (
						<Text fontSize='200'>{invoice.customer.phoneNumber}</Text>
					)}
				</Box>
				<Box flex={2} display='flex' flexDirection='column' borderTop='2px solid black'>
					<Text fontSize='200' fontWeight='semibold'>
						DESCRIPTION
					</Text>
					<Text fontSize='200'>{invoice.description}</Text>
				</Box>
			</Box>
		</>
	);
};

export default InvoiceHeaderPreview;
