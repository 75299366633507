import {Box} from '@sproutsocial/racine';
import {Outlet, redirect, useLoaderData} from 'react-router';
import {createBrowserRouter, useParams} from 'react-router-dom';
import InvoicePreview from './components/invoices/InvoicePreview';
import AppShell from './components/AppShell';
import InvoiceEditPage from './components/invoices/InvoiceEditPage';
import InvoiceList from './components/invoices/InvoiceList';
import TransactionsList from './components/transactions/TransactionsList';
import TagManagement from './components/tags/TagManagement';
import Report from './components/reports/Report';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import CustomerInvoiceList from './components/customers/CustomerInvoiceList';
import CustomerEdit from './components/customers/CustomerEdit';
import ProjectList from './components/projects/ProjectList';
import NewProject from './components/projects/NewProject';
import {initializeReports, type ReportConfig, REPORTS} from './reports';
import ViewProject, {ViewProjectTabs} from './components/projects/ViewProject';
import CustomersList from './components/customers/CustomersList';
import CustomerProjectList from './components/customers/CustomerProjectList';
import Trips from './components/trips/Trips';

export default createBrowserRouter([
	{
		id: 'login',
		path: '/login',
		loader: async () => {
			const response = await fetch('/api/sessions/validate');

			if (response.ok) {
				return redirect('/');
			}

			return null;
		},
		Component: Login,
	},
	{
		id: 'root',
		path: '/',
		loader: async () => {
			const response = await fetch('/api/sessions/validate');

			if (!response.ok) {
				return redirect('/login');
			}

			return null;
		},
		Component: AppShell,
		children: [
			{
				id: 'dashboard',
				index: true,
				Component: Dashboard,
			},
			{
				id: 'projects',
				path: '/projects',
				children: [
					{
						id: 'projects-list',
						index: true,
						Component: ProjectList,
					},
					{
						id: 'new-project',
						path: '/projects/new',
						Component: NewProject,
					},
					{
						id: 'view-project',
						path: '/projects/:projectId/:projectTab?',
						loader: ({params: {projectId, projectTab}}) => {
							if (!Number.isInteger(parseInt(projectId ?? '', 10))) {
								throw new Response('Invalid project', {
									status: 400,
									statusText: 'Bad Request',
								});
							}

							// TODO: Validate project ID and send 404?

							if (!projectTab || !ViewProjectTabs.includes(projectTab)) {
								return redirect(`/projects/${projectId}/${ViewProjectTabs[0]}`);
							}

							return null;
						},
						Component: () => {
							const {projectId, projectTab} = useParams();

							return (
								<ViewProject
									projectId={parseInt(projectId!, 10)}
									selectedTab={projectTab!}
								/>
							);
						},
					},
				],
			},
			{
				id: 'customers',
				path: '/customers',
				children: [
					{
						id: 'customers-list',
						index: true,
						Component: CustomersList,
					},
					{
						id: 'new-customer',
						path: '/customers/new',
						Component: () => {
							return <CustomerEdit customerId={null} />;
						},
					},
					{
						id: 'edit-customer',
						path: '/customers/:customerId/edit',
						Component: () => {
							const {customerId} = useParams();

							return <CustomerEdit customerId={customerId!} />;
						},
					},
					{
						id: 'customer-invoice-list',
						path: '/customers/:customerId/invoices',
						Component: () => {
							const {customerId} = useParams();

							return <CustomerInvoiceList customerId={customerId!} />;
						},
					},
					{
						id: 'customer-project-list',
						path: '/customers/:customerId/projects',
						Component: () => {
							const {customerId} = useParams();

							return <CustomerProjectList customerId={customerId!} />;
						},
					},
				],
			},
			{
				id: 'invoices',
				path: '/invoices',
				children: [
					{
						id: 'invoices-list',
						index: true,
						Component: InvoiceList,
					},
					{
						id: 'update-invoice',
						path: '/invoices/:invoiceId/edit',
						Component: () => {
							const {invoiceId} = useParams();

							return <InvoiceEditPage invoiceId={parseInt(invoiceId!, 10) ?? null} />;
						},
					},
					{
						id: 'new-invoice',
						path: '/invoices/new',
						Component: () => {
							return <InvoiceEditPage invoiceId={null} />;
						},
					},
				],
			},
			{
				id: 'transactions',
				path: '/transactions',
				children: [
					{
						id: 'transactions-list',
						index: true,
						Component: TransactionsList,
					},
				],
			},
			{
				id: 'trips',
				path: '/trips',
				children: [
					{
						id: 'trips-index',
						index: true,
						Component: Trips,
					},
				],
			},
			{
				id: 'reports',
				path: '/reports',
				Component: () => {
					return (
						<Box width='100%'>
							<Outlet />
						</Box>
					);
				},
				children: [
					{
						id: 'specific-report',
						path: '/reports/:reportId',
						loader: async ({params}) => {
							await initializeReports();

							const report = REPORTS.find(({id}) => id === params.reportId);
							if (!report) {
								throw new Response('Invalid report', {
									status: 404,
									statusText: 'Not Found',
								});
							}

							return report;
						},
						Component: () => {
							const report = useLoaderData() as ReportConfig;

							return <Report report={report} />;
						},
					},
				],
			},
			{
				id: 'tag-management',
				path: '/tags',
				Component: TagManagement,
			},
		],
	},
	{
		id: 'invoice-preview',
		path: '/preview/:invoiceId',
		Component: () => {
			const {invoiceId} = useParams();

			return <InvoicePreview invoiceId={parseInt(invoiceId!, 10)} />;
		},
	},
]);
