import {memo, type ReactElement} from 'react';
import {List} from './List';
import Table from './Table';
import {useIsMobile} from '../util/mobile';
import {Box} from '@sproutsocial/racine';

interface MobileFriendlyItemDisplayProps {
	list: ReactElement<typeof List>;
	table: ReactElement<typeof Table>;
	[key: string]: any;
}

const MobileFriendlyItemDisplay = memo(({list, table, ...rest}: MobileFriendlyItemDisplayProps) => {
	const isMobile = useIsMobile();

	return (
		<Box {...rest}>
			{isMobile ? list : table}
		</Box>
	);
});

export default MobileFriendlyItemDisplay;