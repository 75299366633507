/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\tquery GetCategories {\n\t\tcategoryGroups {\n\t\t\tid\n\t\t\tname\n\t\t\thidden\n\t\t\tpriority\n\t\t\tcategories {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t}\n\t\t}\n\t}\n": types.GetCategoriesDocument,
    "\n\tquery GetCustomersForCustomerPicker {\n\t\tcustomers {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n": types.GetCustomersForCustomerPickerDocument,
    "\n\tquery GetDashboardAccounts {\n\t\taccounts {\n\t\t\tid\n\t\t\tname\n\t\t\tbalance\n\t\t\tclosed\n\t\t}\n\t}\n": types.GetDashboardAccountsDocument,
    "\n\tquery GetDashboardTransactions($startDate: String, $endDate: String) {\n\t\ttransactions(startDate: $startDate, endDate: $endDate, categoryIds: [], tagIds: []) {\n\t\t\tdate\n\t\t\tamount\n\t\t}\n\t}\n": types.GetDashboardTransactionsDocument,
    "\n\tmutation SyncData {\n\t\tsyncWithYnab\n\t}\n": types.SyncDataDocument,
    "\n\tquery GetCustomer($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tphoneNumber\n\t\t\tnotes\n\t\t}\n\t}\n": types.GetCustomerDocument,
    "\n\tmutation CreateCustomer($customer: NewCustomerInput!) {\n\t\tcreateCustomer(customer: $customer)\n\t}\n": types.CreateCustomerDocument,
    "\n\tmutation UpdateCustomer($customerId: String!, $customer: NewCustomerInput!) {\n\t\tupdateCustomer(customerId: $customerId, customer: $customer)\n\t}\n": types.UpdateCustomerDocument,
    "\n\tquery GetCustomerInvoices($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tname\n\t\t\tinvoices {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\titems {\n\t\t\t\t\ttype\n\t\t\t\t\tquantity\n\t\t\t\t\tprice\n\t\t\t\t\ttaxed\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GetCustomerInvoicesDocument,
    "\n\tquery GetCustomerProjects($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tname\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tstartDate\n\t\t\t}\n\t\t}\n\t}\n": types.GetCustomerProjectsDocument,
    "\n\tquery GetCustomersList {\n\t\tcustomers {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tphoneNumber\n\t\t\tnotes\n\t\t\tinvoices {\n\t\t\t\tid\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n": types.GetCustomersListDocument,
    "\n\tquery GetAllInvoices {\n\t\tinvoices {\n\t\t\tid\n\t\t\tdescription\n\t\t}\n\t}\n": types.GetAllInvoicesDocument,
    "\n\tquery GetProjectsForPicker {\n\t\tprojects {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n": types.GetProjectsForPickerDocument,
    "\n\tquery GetInvoice($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\tdate\n\t\t\tdescription\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t}\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttype\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n": types.GetInvoiceDocument,
    "\n\tmutation SaveInvoice($invoice: InvoiceInput!) {\n\t\tsaveInvoice(invoice: $invoice)\n\t}\n": types.SaveInvoiceDocument,
    "\n\tquery GetInvoiceFooter($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\titems {\n\t\t\t\ttype\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n": types.GetInvoiceFooterDocument,
    "\n\tquery GetInvoiceHeader($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\tdate\n\t\t\tdescription\n\t\t\tcustomer {\n\t\t\t\tname\n\t\t\t\temail\n\t\t\t\tphoneNumber\n\t\t\t}\n\t\t}\n\t}\n": types.GetInvoiceHeaderDocument,
    "\n\tquery GetInvoicesForInvoiceList {\n\t\tinvoices {\n\t\t\tid\n\t\t\tdate\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\titems {\n\t\t\t\ttype\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n": types.GetInvoicesForInvoiceListDocument,
    "\n\tquery GetInvoicePreviewShell($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttype\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tprice\n\t\t\t\tquantity\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n": types.GetInvoicePreviewShellDocument,
    "\n\tmutation CreateProject($newProject: NewProjectInput) {\n\t\tcreateProject(project: $newProject) {\n\t\t\tid\n\t\t}\n\t}\n": types.CreateProjectDocument,
    "\n\tquery GetProjectFiles($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tfiles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tmimeType\n\t\t\t\tsize\n\t\t\t}\n\t\t}\n\t}\n": types.GetProjectFilesDocument,
    "\n\tquery GetProjectList {\n\t\tprojects {\n\t\t\tid\n\t\t\tname\n\t\t\tdescription\n\t\t\tstartDate\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\ttransactions {\n\t\t\t\tamount\n\t\t\t}\n\t\t}\n\t}\n": types.GetProjectListDocument,
    "\n\tquery GetProjectNotes($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tnotes {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t}\n": types.GetProjectNotesDocument,
    "\n\tmutation CreateNote($note: NewNoteInput, $projectId: Int) {\n\t\tcreateNote(note: $note, projectId: $projectId) {\n\t\t\tid\n\t\t}\n\t}\n": types.CreateNoteDocument,
    "\n\tmutation UpdateNote($note: UpdatedNoteInput) {\n\t\tupdateNote(note: $note) {\n\t\t\tid\n\t\t}\n\t}\n": types.UpdateNoteDocument,
    "\n\tmutation UpdateProject($project: UpdatedProjectInput!) {\n\t\tupdateProject(project: $project) {\n\t\t\tid\n\t\t}\n\t}\n": types.UpdateProjectDocument,
    "\n\tquery GetProjectOverview($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tdescription\n\t\t\tstartDate\n\t\t\tendDate\n\t\t\thoursLogged\n\t\t\tcost\n\t\t\tdeposit\n\t\t\tinvoice {\n\t\t\t\tid\n\t\t\t\tdescription\n\t\t\t}\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n": types.GetProjectOverviewDocument,
    "\n\tquery GetProjectTransactions($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\ttransactions {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\tamount\n\t\t\t\tmemo\n\t\t\t\tpayee\n\t\t\t\tpaymentAccount {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\ttags {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t}\n\t\t\t\tprojects {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tcategory {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\thidden\n\t\t\t\t\tpriority\n\t\t\t\t\tcategoryGroup {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GetProjectTransactionsDocument,
    "\n\tquery GetProjectMetadata($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\ttransactions {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\tamount\n\t\t\t\tmemo\n\t\t\t\tpayee\n\t\t\t\tpaymentAccount {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\ttags {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t}\n\t\t\t\tprojects {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tcategory {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\thidden\n\t\t\t\t\tpriority\n\t\t\t\t\tcategoryGroup {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GetProjectMetadataDocument,
    "\n\tquery GetTransactionsForReport(\n\t\t$startDate: String\n\t\t$endDate: String\n\t\t$categoryIds: [Int!]\n\t\t$tagIds: [Int!]\n\t\t$excludedTagIds: [Int!]\n\t) {\n\t\ttransactions(\n\t\t\tstartDate: $startDate\n\t\t\tendDate: $endDate\n\t\t\tcategoryIds: $categoryIds\n\t\t\ttagIds: $tagIds\n\t\t\texcludedTagIds: $excludedTagIds\n\t\t) {\n\t\t\tid\n\t\t\tdate\n\t\t\tamount\n\t\t\tmemo\n\t\t\tpayee\n\t\t\ttags {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tcategory {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t\tcategoryGroup {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GetTransactionsForReportDocument,
    "\n\tquery GetTags {\n\t\ttags {\n\t\t\tid\n\t\t}\n\t}\n": types.GetTagsDocument,
    "\n\tmutation CreateTag($newTag: NewTagInput) {\n\t\tcreateTag(newTag: $newTag) {\n\t\t\tid\n\t\t}\n\t}\n": types.CreateTagDocument,
    "\n\tquery GetTag($id: Int!) {\n\t\ttag(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tcolor\n\t\t\tparentTag {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t\tparentTag {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t\tparentTag {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\tcolor\n\t\t\t\t\t\tparentTag {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\tcolor\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GetTagDocument,
    "\n\tquery GetTagsIdsForTagPickerModal {\n\t\ttags {\n\t\t\tid\n\t\t}\n\t}\n": types.GetTagsIdsForTagPickerModalDocument,
    "\n\tquery GetTransactionsForUserFilters(\n\t\t$startDate: String\n\t\t$endDate: String\n\t\t$categoryIds: [Int!]\n\t\t$tagIds: [Int!]\n\t\t$excludedTagIds: [Int!]\n\t\t$projectIds: [Int!]\n\t) {\n\t\ttransactions(\n\t\t\tstartDate: $startDate\n\t\t\tendDate: $endDate\n\t\t\tcategoryIds: $categoryIds\n\t\t\ttagIds: $tagIds\n\t\t\texcludedTagIds: $excludedTagIds\n\t\t\tprojectIds: $projectIds\n\t\t) {\n\t\t\tid\n\t\t\tdate\n\t\t\tamount\n\t\t\tmemo\n\t\t\tpayee\n\t\t\tpaymentAccount {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\ttags {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tcategory {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t\tcategoryGroup {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GetTransactionsForUserFiltersDocument,
    "\n\tmutation TagTransactions($transactionIds: [Int!]!, $tagIds: [Int!]!) {\n\t\ttagTransactions(transactionIds: $transactionIds, tagIds: $tagIds)\n\t}\n": types.TagTransactionsDocument,
    "\n\tmutation UntagTransactions($transactionIds: [Int!]!, $tagIds: [Int!]!) {\n\t\tuntagTransactions(transactionIds: $transactionIds, tagIds: $tagIds)\n\t}\n": types.UntagTransactionsDocument,
    "\n\tmutation AddTransactionsToProjectsInTable($transactionIds: [Int!]!, $projectIds: [Int!]!) {\n\t\taddTransactionsToProjects(transactionIds: $transactionIds, projectIds: $projectIds)\n\t}\n": types.AddTransactionsToProjectsInTableDocument,
    "\n\tmutation RemoveTransactionsFromProjectsInTable($transactionIds: [Int!]!, $projectIds: [Int!]!) {\n\t\tremoveTransactionsFromProjects(transactionIds: $transactionIds, projectIds: $projectIds)\n\t}\n": types.RemoveTransactionsFromProjectsInTableDocument,
    "\n\tquery GetTrips($year: Int!) {\n\t\ttrips(year: $year) {\n\t\t\tid\n\t\t\ttripDate\n\t\t\tdestination\n\t\t\tmileage\n\t\t\tmemo\n\t\t}\n\t}\n": types.GetTripsDocument,
    "\n\tmutation CreateTrip($newTrip: NewTripInput) {\n\t\tcreateTrip(newTrip: $newTrip) {\n\t\t\tid\n\t\t}\n\t}\n": types.CreateTripDocument,
    "\n\tmutation DeleteTrip($tripId: Int!) {\n\t\tdeleteTrip(id: $tripId)\n\t}\n": types.DeleteTripDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetCategories {\n\t\tcategoryGroups {\n\t\t\tid\n\t\t\tname\n\t\t\thidden\n\t\t\tpriority\n\t\t\tcategories {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetCategories {\n\t\tcategoryGroups {\n\t\t\tid\n\t\t\tname\n\t\t\thidden\n\t\t\tpriority\n\t\t\tcategories {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetCustomersForCustomerPicker {\n\t\tcustomers {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetCustomersForCustomerPicker {\n\t\tcustomers {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetDashboardAccounts {\n\t\taccounts {\n\t\t\tid\n\t\t\tname\n\t\t\tbalance\n\t\t\tclosed\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetDashboardAccounts {\n\t\taccounts {\n\t\t\tid\n\t\t\tname\n\t\t\tbalance\n\t\t\tclosed\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetDashboardTransactions($startDate: String, $endDate: String) {\n\t\ttransactions(startDate: $startDate, endDate: $endDate, categoryIds: [], tagIds: []) {\n\t\t\tdate\n\t\t\tamount\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetDashboardTransactions($startDate: String, $endDate: String) {\n\t\ttransactions(startDate: $startDate, endDate: $endDate, categoryIds: [], tagIds: []) {\n\t\t\tdate\n\t\t\tamount\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation SyncData {\n\t\tsyncWithYnab\n\t}\n"): (typeof documents)["\n\tmutation SyncData {\n\t\tsyncWithYnab\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetCustomer($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tphoneNumber\n\t\t\tnotes\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetCustomer($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tphoneNumber\n\t\t\tnotes\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation CreateCustomer($customer: NewCustomerInput!) {\n\t\tcreateCustomer(customer: $customer)\n\t}\n"): (typeof documents)["\n\tmutation CreateCustomer($customer: NewCustomerInput!) {\n\t\tcreateCustomer(customer: $customer)\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation UpdateCustomer($customerId: String!, $customer: NewCustomerInput!) {\n\t\tupdateCustomer(customerId: $customerId, customer: $customer)\n\t}\n"): (typeof documents)["\n\tmutation UpdateCustomer($customerId: String!, $customer: NewCustomerInput!) {\n\t\tupdateCustomer(customerId: $customerId, customer: $customer)\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetCustomerInvoices($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tname\n\t\t\tinvoices {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\titems {\n\t\t\t\t\ttype\n\t\t\t\t\tquantity\n\t\t\t\t\tprice\n\t\t\t\t\ttaxed\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetCustomerInvoices($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tname\n\t\t\tinvoices {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\titems {\n\t\t\t\t\ttype\n\t\t\t\t\tquantity\n\t\t\t\t\tprice\n\t\t\t\t\ttaxed\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetCustomerProjects($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tname\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tstartDate\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetCustomerProjects($customerId: String!) {\n\t\tcustomer(id: $customerId) {\n\t\t\tname\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tstartDate\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetCustomersList {\n\t\tcustomers {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tphoneNumber\n\t\t\tnotes\n\t\t\tinvoices {\n\t\t\t\tid\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetCustomersList {\n\t\tcustomers {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tphoneNumber\n\t\t\tnotes\n\t\t\tinvoices {\n\t\t\t\tid\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetAllInvoices {\n\t\tinvoices {\n\t\t\tid\n\t\t\tdescription\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetAllInvoices {\n\t\tinvoices {\n\t\t\tid\n\t\t\tdescription\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetProjectsForPicker {\n\t\tprojects {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetProjectsForPicker {\n\t\tprojects {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetInvoice($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\tdate\n\t\t\tdescription\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t}\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttype\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetInvoice($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\tdate\n\t\t\tdescription\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t}\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttype\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation SaveInvoice($invoice: InvoiceInput!) {\n\t\tsaveInvoice(invoice: $invoice)\n\t}\n"): (typeof documents)["\n\tmutation SaveInvoice($invoice: InvoiceInput!) {\n\t\tsaveInvoice(invoice: $invoice)\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetInvoiceFooter($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\titems {\n\t\t\t\ttype\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetInvoiceFooter($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\titems {\n\t\t\t\ttype\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetInvoiceHeader($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\tdate\n\t\t\tdescription\n\t\t\tcustomer {\n\t\t\t\tname\n\t\t\t\temail\n\t\t\t\tphoneNumber\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetInvoiceHeader($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\tdate\n\t\t\tdescription\n\t\t\tcustomer {\n\t\t\t\tname\n\t\t\t\temail\n\t\t\t\tphoneNumber\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetInvoicesForInvoiceList {\n\t\tinvoices {\n\t\t\tid\n\t\t\tdate\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\titems {\n\t\t\t\ttype\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetInvoicesForInvoiceList {\n\t\tinvoices {\n\t\t\tid\n\t\t\tdate\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\titems {\n\t\t\t\ttype\n\t\t\t\tquantity\n\t\t\t\tprice\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetInvoicePreviewShell($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttype\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tprice\n\t\t\t\tquantity\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetInvoicePreviewShell($id: Int!) {\n\t\tinvoice(id: $id) {\n\t\t\tid\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttype\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tprice\n\t\t\t\tquantity\n\t\t\t\ttaxed\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation CreateProject($newProject: NewProjectInput) {\n\t\tcreateProject(project: $newProject) {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation CreateProject($newProject: NewProjectInput) {\n\t\tcreateProject(project: $newProject) {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetProjectFiles($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tfiles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tmimeType\n\t\t\t\tsize\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetProjectFiles($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tfiles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tdescription\n\t\t\t\tmimeType\n\t\t\t\tsize\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetProjectList {\n\t\tprojects {\n\t\t\tid\n\t\t\tname\n\t\t\tdescription\n\t\t\tstartDate\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\ttransactions {\n\t\t\t\tamount\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetProjectList {\n\t\tprojects {\n\t\t\tid\n\t\t\tname\n\t\t\tdescription\n\t\t\tstartDate\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\ttransactions {\n\t\t\t\tamount\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetProjectNotes($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tnotes {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetProjectNotes($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tnotes {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation CreateNote($note: NewNoteInput, $projectId: Int) {\n\t\tcreateNote(note: $note, projectId: $projectId) {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation CreateNote($note: NewNoteInput, $projectId: Int) {\n\t\tcreateNote(note: $note, projectId: $projectId) {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation UpdateNote($note: UpdatedNoteInput) {\n\t\tupdateNote(note: $note) {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation UpdateNote($note: UpdatedNoteInput) {\n\t\tupdateNote(note: $note) {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation UpdateProject($project: UpdatedProjectInput!) {\n\t\tupdateProject(project: $project) {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation UpdateProject($project: UpdatedProjectInput!) {\n\t\tupdateProject(project: $project) {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetProjectOverview($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tdescription\n\t\t\tstartDate\n\t\t\tendDate\n\t\t\thoursLogged\n\t\t\tcost\n\t\t\tdeposit\n\t\t\tinvoice {\n\t\t\t\tid\n\t\t\t\tdescription\n\t\t\t}\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetProjectOverview($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tdescription\n\t\t\tstartDate\n\t\t\tendDate\n\t\t\thoursLogged\n\t\t\tcost\n\t\t\tdeposit\n\t\t\tinvoice {\n\t\t\t\tid\n\t\t\t\tdescription\n\t\t\t}\n\t\t\tcustomer {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetProjectTransactions($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\ttransactions {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\tamount\n\t\t\t\tmemo\n\t\t\t\tpayee\n\t\t\t\tpaymentAccount {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\ttags {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t}\n\t\t\t\tprojects {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tcategory {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\thidden\n\t\t\t\t\tpriority\n\t\t\t\t\tcategoryGroup {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetProjectTransactions($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\ttransactions {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\tamount\n\t\t\t\tmemo\n\t\t\t\tpayee\n\t\t\t\tpaymentAccount {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\ttags {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t}\n\t\t\t\tprojects {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tcategory {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\thidden\n\t\t\t\t\tpriority\n\t\t\t\t\tcategoryGroup {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetProjectMetadata($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\ttransactions {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\tamount\n\t\t\t\tmemo\n\t\t\t\tpayee\n\t\t\t\tpaymentAccount {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\ttags {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t}\n\t\t\t\tprojects {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tcategory {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\thidden\n\t\t\t\t\tpriority\n\t\t\t\t\tcategoryGroup {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetProjectMetadata($id: Int!) {\n\t\tproject(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\ttransactions {\n\t\t\t\tid\n\t\t\t\tdate\n\t\t\t\tamount\n\t\t\t\tmemo\n\t\t\t\tpayee\n\t\t\t\tpaymentAccount {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\ttags {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t}\n\t\t\t\tprojects {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tcategory {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\thidden\n\t\t\t\t\tpriority\n\t\t\t\t\tcategoryGroup {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetTransactionsForReport(\n\t\t$startDate: String\n\t\t$endDate: String\n\t\t$categoryIds: [Int!]\n\t\t$tagIds: [Int!]\n\t\t$excludedTagIds: [Int!]\n\t) {\n\t\ttransactions(\n\t\t\tstartDate: $startDate\n\t\t\tendDate: $endDate\n\t\t\tcategoryIds: $categoryIds\n\t\t\ttagIds: $tagIds\n\t\t\texcludedTagIds: $excludedTagIds\n\t\t) {\n\t\t\tid\n\t\t\tdate\n\t\t\tamount\n\t\t\tmemo\n\t\t\tpayee\n\t\t\ttags {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tcategory {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t\tcategoryGroup {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetTransactionsForReport(\n\t\t$startDate: String\n\t\t$endDate: String\n\t\t$categoryIds: [Int!]\n\t\t$tagIds: [Int!]\n\t\t$excludedTagIds: [Int!]\n\t) {\n\t\ttransactions(\n\t\t\tstartDate: $startDate\n\t\t\tendDate: $endDate\n\t\t\tcategoryIds: $categoryIds\n\t\t\ttagIds: $tagIds\n\t\t\texcludedTagIds: $excludedTagIds\n\t\t) {\n\t\t\tid\n\t\t\tdate\n\t\t\tamount\n\t\t\tmemo\n\t\t\tpayee\n\t\t\ttags {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tcategory {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t\tcategoryGroup {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetTags {\n\t\ttags {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetTags {\n\t\ttags {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation CreateTag($newTag: NewTagInput) {\n\t\tcreateTag(newTag: $newTag) {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation CreateTag($newTag: NewTagInput) {\n\t\tcreateTag(newTag: $newTag) {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetTag($id: Int!) {\n\t\ttag(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tcolor\n\t\t\tparentTag {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t\tparentTag {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t\tparentTag {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\tcolor\n\t\t\t\t\t\tparentTag {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\tcolor\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetTag($id: Int!) {\n\t\ttag(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tcolor\n\t\t\tparentTag {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t\tparentTag {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcolor\n\t\t\t\t\tparentTag {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\tcolor\n\t\t\t\t\t\tparentTag {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\tcolor\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetTagsIdsForTagPickerModal {\n\t\ttags {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetTagsIdsForTagPickerModal {\n\t\ttags {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetTransactionsForUserFilters(\n\t\t$startDate: String\n\t\t$endDate: String\n\t\t$categoryIds: [Int!]\n\t\t$tagIds: [Int!]\n\t\t$excludedTagIds: [Int!]\n\t\t$projectIds: [Int!]\n\t) {\n\t\ttransactions(\n\t\t\tstartDate: $startDate\n\t\t\tendDate: $endDate\n\t\t\tcategoryIds: $categoryIds\n\t\t\ttagIds: $tagIds\n\t\t\texcludedTagIds: $excludedTagIds\n\t\t\tprojectIds: $projectIds\n\t\t) {\n\t\t\tid\n\t\t\tdate\n\t\t\tamount\n\t\t\tmemo\n\t\t\tpayee\n\t\t\tpaymentAccount {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\ttags {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tcategory {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t\tcategoryGroup {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetTransactionsForUserFilters(\n\t\t$startDate: String\n\t\t$endDate: String\n\t\t$categoryIds: [Int!]\n\t\t$tagIds: [Int!]\n\t\t$excludedTagIds: [Int!]\n\t\t$projectIds: [Int!]\n\t) {\n\t\ttransactions(\n\t\t\tstartDate: $startDate\n\t\t\tendDate: $endDate\n\t\t\tcategoryIds: $categoryIds\n\t\t\ttagIds: $tagIds\n\t\t\texcludedTagIds: $excludedTagIds\n\t\t\tprojectIds: $projectIds\n\t\t) {\n\t\t\tid\n\t\t\tdate\n\t\t\tamount\n\t\t\tmemo\n\t\t\tpayee\n\t\t\tpaymentAccount {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\ttags {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcolor\n\t\t\t}\n\t\t\tprojects {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tcategory {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\thidden\n\t\t\t\tpriority\n\t\t\t\tcategoryGroup {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation TagTransactions($transactionIds: [Int!]!, $tagIds: [Int!]!) {\n\t\ttagTransactions(transactionIds: $transactionIds, tagIds: $tagIds)\n\t}\n"): (typeof documents)["\n\tmutation TagTransactions($transactionIds: [Int!]!, $tagIds: [Int!]!) {\n\t\ttagTransactions(transactionIds: $transactionIds, tagIds: $tagIds)\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation UntagTransactions($transactionIds: [Int!]!, $tagIds: [Int!]!) {\n\t\tuntagTransactions(transactionIds: $transactionIds, tagIds: $tagIds)\n\t}\n"): (typeof documents)["\n\tmutation UntagTransactions($transactionIds: [Int!]!, $tagIds: [Int!]!) {\n\t\tuntagTransactions(transactionIds: $transactionIds, tagIds: $tagIds)\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation AddTransactionsToProjectsInTable($transactionIds: [Int!]!, $projectIds: [Int!]!) {\n\t\taddTransactionsToProjects(transactionIds: $transactionIds, projectIds: $projectIds)\n\t}\n"): (typeof documents)["\n\tmutation AddTransactionsToProjectsInTable($transactionIds: [Int!]!, $projectIds: [Int!]!) {\n\t\taddTransactionsToProjects(transactionIds: $transactionIds, projectIds: $projectIds)\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation RemoveTransactionsFromProjectsInTable($transactionIds: [Int!]!, $projectIds: [Int!]!) {\n\t\tremoveTransactionsFromProjects(transactionIds: $transactionIds, projectIds: $projectIds)\n\t}\n"): (typeof documents)["\n\tmutation RemoveTransactionsFromProjectsInTable($transactionIds: [Int!]!, $projectIds: [Int!]!) {\n\t\tremoveTransactionsFromProjects(transactionIds: $transactionIds, projectIds: $projectIds)\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tquery GetTrips($year: Int!) {\n\t\ttrips(year: $year) {\n\t\t\tid\n\t\t\ttripDate\n\t\t\tdestination\n\t\t\tmileage\n\t\t\tmemo\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetTrips($year: Int!) {\n\t\ttrips(year: $year) {\n\t\t\tid\n\t\t\ttripDate\n\t\t\tdestination\n\t\t\tmileage\n\t\t\tmemo\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation CreateTrip($newTrip: NewTripInput) {\n\t\tcreateTrip(newTrip: $newTrip) {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation CreateTrip($newTrip: NewTripInput) {\n\t\tcreateTrip(newTrip: $newTrip) {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\tmutation DeleteTrip($tripId: Int!) {\n\t\tdeleteTrip(id: $tripId)\n\t}\n"): (typeof documents)["\n\tmutation DeleteTrip($tripId: Int!) {\n\t\tdeleteTrip(id: $tripId)\n\t}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;